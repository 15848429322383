import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@hiyllo/ux/styled';
import { MoopsyError } from '@moopsyjs/core';

const FullWidthCenter = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const LoadingSpinner = (): JSX.Element => (
  <motion.div style={{
    height: '1em',
    width: '1em'
  }} initial={{ rotate: '0deg' }} animate={{ rotate: '360deg' }} transition={{
    ease: 'linear',
    repeat: Infinity,
    repeatDelay: 0,
    duration: 0.75
  }}>
    <FontAwesomeIcon icon={faSpinnerThird} style={{
      height: '1em',
      width: '1em'
    }}/>
  </motion.div>
);

export const GrowLoading = React.memo(function GrowLoading (): JSX.Element {
  return (
    <FullWidthCenter>
      <LoadingSpinner/>
    </FullWidthCenter>
  );
});

export const ErrorView = React.memo(function ErrorView (props: {error: MoopsyError}): JSX.Element {
  return (
    <FullWidthCenter>
      <div style={{ color: 'red' }}>{props.error.message}</div>
    </FullWidthCenter>
  );
});
