import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

const IS_MOBILE = window.innerHeight > window.innerWidth;

export const EffortlesslyConnected = React.memo(function EffortlesslyConnected(): JSX.Element {
    const ref = React.useRef(null);
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ['start end', 'end start'],
      container: { current: document.getElementById('root') }
    });
    const translateX1 = useTransform(scrollYProgress, [0.1, IS_MOBILE ? 0.5 : 1], ['75', IS_MOBILE ? '-500%' : '-400%']);
    const translateX2 = useTransform(scrollYProgress, [IS_MOBILE ? 0.45 : 0.3, IS_MOBILE ? 0.8 : 1], ['150%', IS_MOBILE ? '-450%' : '-250%']);

    return (
        <div style={{ pointerEvents: 'none', padding: 40, width: 'calc(100% - 80px)', rotate: IS_MOBILE ? '35deg' : '' }} ref={ref}>
          <motion.div style={{ fontSize: IS_MOBILE ? 400 : 600, translateX: translateX1 }}>
            Effortlessly
          </motion.div>
          <motion.div style={{ fontSize: IS_MOBILE ? 400 : 600, translateX: translateX2 }}>
            Connected
          </motion.div>
        </div>
    );
});
