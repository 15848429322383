/* eslint-disable @typescript-eslint/no-base-to-string */
import React from 'react';
import {
  Header, Label, PageContainer
} from '../common';

import * as GetPlatformBP from '../../blueprints/platforms/get-platform';
import * as GetStardateDataBP from '../../blueprints/platforms/get-stardate-data';
import * as SavePaymentMethodBP from '../../blueprints/organizations/save-payment-method';
import * as SetPlatformRestrictedBP from '../../blueprints/platforms/set-platform-restricted';
import * as WipePlatformBP from '../../blueprints/platforms/wipe-platform';
import * as ManualPerformBillingBP from '../../blueprints/subscriptions/manual-perform-billing';
import { seamlessAuth, seamlessClient } from '../../seamless';
import { useParams } from 'react-router-dom';
import {
  ErrorView, GrowLoading, LoadingSpinner
} from '../ux/loading-spinner';
import { Badge } from '@hiyllo/ux/badge';
import { Platform, PlatformStatusEnum } from '../../types/platforms';
import {
  faCircle, faCircleDashed, faEye, faEyeSlash, faGlobe, faRefresh
} from '@fortawesome/pro-light-svg-icons';
import { Card } from '@hiyllo/ux/surface';
import { styled } from '@hiyllo/ux/styled';
import { CircleButton } from '@hiyllo/ux/circle-button';
import * as TerminatePlatformBP from '../../blueprints/platforms/terminate-platform';
import { FragileView, type FragileViewRefType } from '@hiyllo/ux/fragile-view';
import { Button } from '@hiyllo/ux/button';
import { InfoPiece } from '../ux/info-piece';
import { Typography } from '@hiyllo/ux/typography';
import moment from 'moment';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

const LargeHeader = styled('div', {
  fontFamily: 'hiyllo',
  fontSize: 46,
  fontWeight: 'bold'
});

const ProgressBarOuter = styled('div', ({ $theme }) => ({
  background: $theme.foreground,
  padding: 1,
  borderRadius: 10,
  overflow: 'hidden',
  width: 200
}));

const ProgressBarInner = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  width: 196,
  padding: 2,
  borderRadius: 'inherit',
  overflow: 'hidden'
}));

const ProgressBarThumb = styled<'div', {progress: number}>('div', ({ $theme, progress }) => ({
  width: Math.floor(progress * 100).toString() + '%',
  height: 15,
  background: $theme.buttonBackground,
  borderRadius: 10
}));

const ProgressBar = React.memo(function ProgressBar (props: {progress: number}): JSX.Element {
  return (
    <div>
      <ProgressBarOuter>
        <ProgressBarInner>
          <ProgressBarThumb progress={props.progress}/>
        </ProgressBarInner>
      </ProgressBarOuter>
    </div>
  );
});

export const PlatformView = React.memo(function PlatformView (): JSX.Element {
  const { organizationUUID, platformUUID } = useParams<{organizationUUID: string, platformUUID: string}>();
  const platformQuery = seamlessClient.useQuery<GetPlatformBP.Plug>(GetPlatformBP, {
    organizationUUID: organizationUUID as string,
    platformUUID: platformUUID as string
  });
  const stardateDataQuery = seamlessClient.useQuery<GetStardateDataBP.Plug>(GetStardateDataBP, {
    tenantId: platformQuery?.data?.platform?.tenantId ?? ''
  });
  const setPlatformRestrictedMutation = seamlessClient.useMutation<SetPlatformRestrictedBP.Plug>(SetPlatformRestrictedBP);
  const self = seamlessAuth.useAuthState();
  const savePaymentMethodMutation = seamlessClient.useMutation<SavePaymentMethodBP.Plug>(SavePaymentMethodBP, { querySideEffects: [platformQuery] });
  const terminatePlatformMutation = seamlessClient.useMutation<TerminatePlatformBP.Plug>(TerminatePlatformBP, { querySideEffects: [platformQuery] });
  const manualPerformBillingMutation = seamlessClient.useMutation<ManualPerformBillingBP.Plug>(ManualPerformBillingBP, { querySideEffects: [platformQuery] });
  const wipePlatformMutation = seamlessClient.useMutation<WipePlatformBP.Plug>(WipePlatformBP, { querySideEffects: [platformQuery] });
  const [changePaymentMethod, setChangePaymentMethod] = React.useState(false);

  React.useEffect(() => {
    const inv = setInterval(() => {
      void platformQuery.refresh({ subtle: true });
    }, 5000);

    return () => {
      clearInterval(inv);
    };
  }, [platformQuery.refresh]);

  const setPlatformRestricted = React.useCallback((restricted: boolean) => {
    void setPlatformRestrictedMutation.call({
      platformUUID: platformUUID as string,
      restricted
    });
  }, [platformUUID, setPlatformRestrictedMutation]);

  const terminate = React.useCallback(() => {
    if (confirm('Terminate?')) {
      void terminatePlatformMutation.call({
        platformUUID: platformUUID as string
      });
    }
  }, []);

  const wipe = React.useCallback(() => {
    const password = prompt('Wipe?');
    if (password) {
      void wipePlatformMutation.call({
        platformUUID: platformUUID as string,
        password
      }).then(() => {
        window.location.href = '/';
      });
    }
  }, [platformUUID, wipePlatformMutation]);

  const elements = useElements();
  const stripe = useStripe();
  const onSavePaymentDetails = React.useCallback(async () => {
    if (elements == null || stripe == null) {
      return console.warn({
        elements,
        stripe
      });
    }

    const { error: submitError } = await elements.submit();
    if (submitError != null) {
      return alert(submitError.message ?? 'There was an issue with your payment details');
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({ elements });

    if (error != null) {
      return alert(error.message ?? 'There was an issue with your payment details');
    }

    await savePaymentMethodMutation.call({
      stripePaymentMethodId: paymentMethod.id,
      organizationUUID: platformQuery.data?.organization.uuid ?? ''
    });
  }, [platformQuery, elements, savePaymentMethodMutation, stripe]);

  if (platformQuery.isLoading) {
    return <GrowLoading/>;
  }

  if (platformQuery.isError) {
    return <ErrorView error={platformQuery.error}/>;
  }

  const { data } = platformQuery;

  const workspaceURL = `https://${data.platform.tenantId}.hiyllo.work`;
  const isAdmin = self?.type === 'hiyllo-employee';

  return (
    <PageContainer>
      <img src="/work/icon-text/gradient.png" style={{
        height: 30,
        width: 'auto'
      }}/>
      <div style={{ height: 15 }}/>
      <LargeHeader>
        {data.platform.name}
      </LargeHeader>
      <div style={{ height: 15 }}/>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15
      }}>
        <div>
          <span style={{ userSelect: 'none' }}>Your Workspace is available at </span><a href={workspaceURL} style={{ color: 'white' }}>{data.platform.tenantId}.hiyllo.work</a>
        </div>

        <div style={{ background: 'rgba(255, 255, 255, 0.1)', padding: 20, borderRadius: 10 }}>
          <div style={{ fontWeight: 'bold', fontSize: 32, marginBottom: 10 }}>
            Your Plan
          </div>
          <div>
            {data.subscription.freeTrial != null ?
              <div style={{ marginBottom: 10 }}>Your free trial will end <b>{moment(data.subscription.freeTrial.expires).format(isAdmin ? 'h:mm a, MM/DD/YYYY' : 'MMM Do, YYYY')}</b></div>
            : null}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
            <div>
              <div>Price per User per Month</div>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                ${data.subscription.billingRate.costPerUser / 100}
              </div>
            </div>
            <div>
              <div>Price per 100GB</div>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                $4.99
              </div>
            </div>
            <div>
              <div>Current Billable Users</div>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                {data.platform.usage?.billableUsersCurrent ?? 1}
              </div>
            </div>
            <div>
              <div>Current Monthly Price</div>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginTop: 2.5 }}>
                ${((data.subscription.billingRate.costPerUser * (data.platform.usage?.billableUsersCurrent ?? 1)) / 100).toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        {data.organization.billing == null || changePaymentMethod ?
          <div style={{ background: 'rgba(255, 255, 255, 0.1)', padding: 20, borderRadius: 10 }}>
            <div style={{ fontSize: 24, fontFamily: 'hiyllo' }}>Setup your payment method now to prevent service interruption</div>
            <div style={{ height: 15 }}/>
            <AddressElement options={{ mode: 'billing' }}/>
            <div style={{ height: 15 }}/>
            <PaymentElement />
            <div style={{ height: 15 }}/>
            <CircleButton
              onClick={onSavePaymentDetails}
              awaitOnClickForLoading
              size={40}
            />
          </div>
        :
          <Button label="Change Payment Method" onClick={() => setChangePaymentMethod(true)} isSecondary/>
        }

        <div>
          For any help please email <b>support@hiyllo.io</b>, and we&apos;ll get back to you within 24 hours.
        </div>

        {isAdmin ?
          <>
            <Card color="background2">
              <Typography.Header>Platform</Typography.Header>
              <div>
                Status: {data.platform.status}
              </div>
              <div>
                <span style={{ userSelect: 'none' }}>Last Synced: </span>{moment(data.platform.usageLastSynced).format('h:mm a, MMM Do')}
              </div>
              <div>
                <span style={{ userSelect: 'none' }}>Last Active: </span>{stardateDataQuery.isError ? <>{stardateDataQuery.error.toString()}</> : stardateDataQuery.isLoading ? 'Loading...' : moment(stardateDataQuery.data.data.lastActive).format('h:mm a, MMM Do')}
              </div>
              <div>
                {data.platform.status === PlatformStatusEnum.restricted ?
                <button onClick={() => setPlatformRestricted(false)}>Unrestrict</button>
                :
                  <button onClick={() => setPlatformRestricted(true)}>Restrict</button>
                }
              </div>
              <div>
              <button onClick={terminate}>Terminate</button>
              <button onClick={wipe}>Wipe</button>
              </div>
            </Card>
            <Card color="background2">
              <Typography.Header>Subscription</Typography.Header>
              <div>
                <b>UUID: </b>{data.subscription.uuid}
              </div>
              <div>
                <b>Next Payment Date: </b>{data.subscription.nextPaymentDate != null ? moment(data.subscription.nextPaymentDate).format('h:mm a MMM Do, YYYY') : 'N/A'}
              </div>
              <div>
                <Button
                  onClick={() => {
                    void manualPerformBillingMutation.call({
                      subscriptionUUID: data.subscription.uuid
                    });
                  }}
                  label="Peform Billing Now"
                  isLoading={manualPerformBillingMutation.isLoading}
                />
              </div>
              {(data.subscription.modifications ?? []).map(modification => (
                <div key={modification.note}>
                  {modification.note} {modification.value.kind} {modification.value.adjustment}
                </div>
              ))}
            </Card>
            <Card color="background2">
              <Typography.Header>Organization</Typography.Header>
              <div>
                <span style={{ userSelect: 'none', fontWeight: 'bold' }}>Root Admin: </span>{data.organization.rootAdmin}
              </div>
            </Card>
          </>
        : null}
      </div>
    </PageContainer>
  );
});
