import React from 'react';
import { seamlessClient } from '../../seamless';

import * as GetOrganizationsBP from '../../blueprints/organizations/get-my-organizations';
import * as GetPlatformsForOrganizationsBP from '../../blueprints/platforms/get-platforms-for-organizations';
import {
  ErrorView, GrowLoading, LoadingSpinner
} from '../ux/loading-spinner';
import { PageContainer } from '../common';
import { Organization } from '../../types/organization';
import { Platform } from '../../types/platforms';
import { styled } from '@hiyllo/ux/styled';

import { SubHeader } from '../ux/typography';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ux/button';
import { Subscribe } from './subscribe';

const PlatformListItemContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 15,
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 20,
  cursor: 'pointer'
}));

const PlatformListItem = React.memo(function PlatformListItem (props: {platform: Platform}): JSX.Element {
  const navigate = useNavigate();

  return (
    <PlatformListItemContainer
      onClick={() => {
        navigate(`/organization/${props.platform.organizationUUID}/platform/${props.platform.uuid}`);
      }}
    >
      <div style={{ height: 30 }}>
        <img src="/work/icon/white-outline.png" style={{
          height: 30,
          width: 'auto',
          paddingLeft: 5
        }}/>
      </div>
      <SubHeader>
        {props.platform.name}
      </SubHeader>
    </PlatformListItemContainer>
  );
});

const OrganizationCard = React.memo(function OrganizationCard (props: {organization: Organization}): JSX.Element {
  const getPlatformsForOrganizations = seamlessClient.useQuery<GetPlatformsForOrganizationsBP.Plug>(GetPlatformsForOrganizationsBP, { organizationUUID: props.organization.uuid });

  return (
    <div>
      {getPlatformsForOrganizations.isError
        ? <div>{getPlatformsForOrganizations.error.description}</div>
        : getPlatformsForOrganizations.isLoading
          ? <LoadingSpinner/>
          : <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15
          }}>
            {getPlatformsForOrganizations.data.platforms.map(platform => (
              <PlatformListItem platform={platform} key={platform.uuid}/>
            ))}
          </div>
      }
    </div>
  );
});

export const Home = (): JSX.Element => {
  const organizationsQuery = seamlessClient.useQuery<GetOrganizationsBP.Plug>(GetOrganizationsBP, null);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (organizationsQuery.data?.goDirectTo != null) {
      navigate(organizationsQuery.data.goDirectTo);
    }
  }, [navigate, organizationsQuery.data]);

  if (organizationsQuery.isLoading) {
    return <GrowLoading/>;
  }

  if (organizationsQuery.isError) {
    return <ErrorView error={organizationsQuery.error}/>;
  }

  const { organizations } = organizationsQuery.data;

  if (organizations.length > 0) {
    return (
      <PageContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15
        }}>
          <div>
            <Button
              color="white"
              text="New Organization"
              onClick={() => navigate('/create-platform/workspace_with_video')}
            />
          </div>
          {organizations.map(organization => (
            <OrganizationCard organization={organization} key={organization.uuid}/>
          ))}
        </div>
      </PageContainer>
    );
  }

  return (
    <Subscribe/>
  );
};
