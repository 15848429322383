import { faFileUpload,
  faChevronDown,
  faChevronsDown,
  faChevronsUp,
  faChevronUp,
  faEquals,
  faUserSlash,
  faMouse,
  faArrowPointer,
  faUserPlus,
  faFolder,
  faEnvelope,
  faCalendar,
  faSquareKanban,
  faComments
} from '@fortawesome/pro-light-svg-icons';
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu
} from '@hiyllo/ux/context-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NymblIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { ListTasksSlimTaskType } from '@hiyllo/omni-common/src/types/tasks/task-item';
import { TaskPillsRow } from '@hiyllo/omni-tasks/main';
import { styled } from '@hiyllo/ux/styled';
import { useTheme } from '@hiyllo/ux/theme';
import { motion, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import React from 'react';
import { EJSONCache } from '@hiyllo/ejson-cache/main';
import { TaskPill } from '@hiyllo/omni-tasks/main/src/ui/common';
import { GradientText } from './landing';

const Container = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    pointerEvents: 'none'
}));

const InputBar = styled('div', ({ $theme }) => ({
    background: 'rgb(40,40,44)'
}));

const Separator = styled('div', ({ $theme }) => ({
    height: 1,
    background: $theme.midground1
}));

const Input = styled('input', ({ $theme }) => ({
    height: 60,
    width: 'calc(100% - 20px)',
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 17.5
}));

const Toolbar = styled('div', ({ $theme }) => ({
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 10,
    paddingRight: 10
}));

const Avatar = styled('img', ({ $theme }) => ({
    width: 40,
    height: 40,
    borderRadius: 10,
    objectFit: 'cover'
}));

const MessageName = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15,
    fontWeight: 'bold'
}));

const MessageText = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15
    // fontWeight: 'bold'
}));

const Message = React.memo(function Message(props: {
    userImage: string;
    name: string;
    text: string;
}): JSX.Element {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Avatar src={props.userImage}/>
            <div>
                <MessageName>{props.name}</MessageName>
                <MessageText>{props.text}</MessageText>
            </div>
        </div>
    );
});

const Dock = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    width: 80,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center'
}));

const Sidebar = styled('div', ({ $theme }) => ({
  background: $theme.background2,
  width: 280,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const PriorityColorMap = (): Record<number, string> => ({
  0: '#d32f2f', // Red
  1: '#f57c00', // Orange
  2: '#1976d2', // Blue
  3: '#00796b', // Aqua
  4: '#388e3c' // Green
});

export const TinyPillContainer = styled<
  'div',
  { overdue?: boolean; noLeftPadding?: boolean }
>('div', ({ overdue, noLeftPadding }) => ({
  background: overdue ? '#d3302f' : 'rgba(255, 255, 255, 0.2)',
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  alignItems: 'center',
  borderRadius: 20,
  paddingLeft: noLeftPadding === true ? 0 : 10,
  fontSize: 12,
  overflow: 'hidden'
}));

const ImageLeftHalfCard = React.memo(function ImageHalfCard(props: {
  imageSrc: string;
  content: JSX.Element
}): JSX.Element {
  return (
    <div style={{ marginBottom: IS_MOBILE ? 80 : 0, overflow: 'hidden', gap: 40, height: IS_MOBILE ? '' : '100vh', display: 'flex', flexDirection: IS_MOBILE ? 'column' : 'row', width: '100%', alignItems: 'center', flexWrap: 'wrap' }}>
      <motion.div
        style={{
          marginLeft: 40,
          boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
          borderRadius: 10,
          overflow: 'hidden'
        }}
      >
        <img
          src={props.imageSrc}
          style={{
            width: IS_MOBILE ? '100vw' : '60vw',
            height: 'auto',
            borderRadius: 5
          }}
        />
      </motion.div>
      <motion.div style={{ width: IS_MOBILE ? '100%' : 0, flexGrow: 1 }}>
        {props.content}
      </motion.div>
    </div>
  );
});

export const PriorityIconsMap = {
  0: faChevronsUp,
  1: faChevronUp,
  2: faEquals,
  3: faChevronDown,
  4: faChevronsDown
};

const JumboText = styled('div', ({ $theme }) => ({
  fontSize: IS_MOBILE ? 42 : 48
  // marginBlock: 10
}));

export const PillContent = styled<'div', { overdue?: boolean }>(
  'div',
  ({ overdue }) => ({
    backgroundColor: overdue ? '#f44336' : '#5f5e62',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
    gap: 5,
    cursor: 'pointer',
    height: 26
  })
);

export const CondensedDetailsPill = React.memo(
  function CondensedDetailsPill(props: {
    task: Omit<ListTasksSlimTaskType, 'assignee'>;
  }): JSX.Element {
    return (
      <TinyPillContainer style={{ paddingLeft: 0, borderRadius: 5 }}>
        <div
          style={{
            height: 25,
            backgroundColor: PriorityColorMap()[props.task.priority],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 5,
            paddingLeft: 5,
            fontSize: 12.5
          }}
        >
          <FontAwesomeIcon
            icon={PriorityIconsMap[props.task.priority]}
            fixedWidth
          />
        </div>
        <div style={{ paddingRight: 5 }}>{props.task.shortId}</div>
      </TinyPillContainer>
    );
  }
);

const IS_MOBILE = window.innerHeight > window.innerWidth;

const cache = new EJSONCache({
  getItem: async () => {
    return null;
  },
  setItem: async () => {
    //
  }
});

const Frame = styled('div', ({ $theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexShrink: 0,
  flexGrow: 0,
  position: 'relative'
}));

const NymblFeatureContainer = React.memo(function NymblFeatureContainer(props: React.PropsWithChildren): JSX.Element {
  const $theme = useTheme();

  return (
    <div style={{ background: $theme.buttonBackground, padding: 2.5, borderRadius: 10, marginLeft: 20, marginRight: 20 }}>
      <div style={{ background: $theme.background1, padding: 10, borderRadius: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
          <div style={{ flexShrink: 0, paddingTop: 1, paddingBottom: 1, height: 15, alignSelf: 'flex-start' }}>
            <NymblIcon width={12.5} color="white"/>
          </div>
          <div style={{ fontSize: 15 }}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
});

export const ScrollNymbl = React.memo(function ScrollNymbl(): JSX.Element {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref
  });
  const $theme = useTheme();

  const meetTextOpacity = useTransform(scrollYProgress, [0, 0.25], [1, 0]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'hidden' }} ref={ref}>
      <motion.div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <motion.div style={{ opacity: meetTextOpacity, paddingBottom: 0 }}>
          <div style={{ fontSize: 40 }}>Thought that&apos;s it? Course not</div>
        </motion.div>
        <motion.div style={{ opacity: meetTextOpacity, paddingBottom: 20 }}>
          <div style={{ fontSize: 100 }}>Meet <b>Nymbl</b></div>
        </motion.div>
        <NymblIcon width={100} color="white"/>
      </motion.div>
      <motion.div style={{ width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 15 }}>
        <NymblFeatureContainer>
          I can summarize long boring emails to a single sentence
        </NymblFeatureContainer>
        <NymblFeatureContainer>
          I can catch you up on all the important developments when you miss a few dozen messages in a thread
        </NymblFeatureContainer>
        <NymblFeatureContainer>
          I can create tasks based on action items derived from chat conversations
        </NymblFeatureContainer>
        <NymblFeatureContainer>
          I can schedule meetings with a few people, or entire teams
        </NymblFeatureContainer>
        <NymblFeatureContainer>
          <div>
            Bottom line, <b>I give your team back hours of their time.</b>
          </div>
        </NymblFeatureContainer>
        <NymblFeatureContainer>
          And the engineers over at Hiyllo are giving me new capabilities
        </NymblFeatureContainer>
      </motion.div>
    </div>
  );
});
